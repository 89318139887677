const Content = {
  shirt: {
    sizes: ['sm', 'm', 'xxl'],
    colors: [
      ['white', 'white'],
      ['black', 'darkslategray'],
      ['red', 'red'],
      ['yellow', 'gold']
    ],
    price: [1200, 1600]
  },
  hoodie: {
    sizes: ['sm', 'l', 'xl'],
    colors: [['white', 'white']],
    price: [2000, 2400]
  },
  sweetshirt: {
    sizes: ['sm', 'm', 'xl'],
    colors: [
      ['white', 'white'],
      ['yellow', 'gold']
    ],
    price: [1800, 2200]
  }
};

export default Content;
